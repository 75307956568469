<template>
  <div :style="slidePosition" class="w-100 h-100">
    <div class="position-absolute text-warning trudeauWrapper "
      style="width:38%;left:5%;top:82.5%;transform:translate(0%,-100%)">
      <inline-svg src="images/Slide8/pierre-trudeau-opt.svg" aria-label="Pierre Trudeau" class="trudeau" width="100%" />
    </div>
    <div iv class="position-absolute text-white " style="width:45%;left:47.5%;top:50%;transform: translateY(-50%)">
      <h3 class="font-vw-3-5 font-w-600 mb-0 fade8" style="opacity:0;">
        1976
      </h3>
      <h6 class="font-vw-2-5 my-0 font-w-600 fade8 " style="opacity:0; ">
        CANADA’S CYCLOTRON ADVANTAGE
      </h6>
      <p class="font-vw-1-35 text-8 mt-3" style="opacity:0; ">
        Attending the official commissioning of the 520 MeV cyclotron, Canada’s
        Prime Minister, the Right Honourable Pierre Trudeau, famously said: “I
        don't really know what a cyclotron is, but I am certainly very happy
        Canada has one.” Indeed, TRIUMF launched a new era in Canada-wide
        physics research, and the new lab quickly became a portal for Canadian
        scientists’ participation in a wave of international collaborations.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { normalize } from "@/utils/ranges"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() {
    this.startLoop()
  },
  methods: {
    slide7(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 500
      })

      animation
        .add({
          targets: ".fade8",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 5000 + 30 * i
        })
        .add({
          targets: ".text-8",

          translateY: ["35%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine"
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    },
    trudeau(percentage) {
      let animation = anime
        .timeline({
          autoplay: false,
          loop: false,
          endDelay: 500
        })
        .add({
          targets: ".trudeau path",
          strokeDashoffset: [anime.setDashoffset, 0],
          easing: "easeInOutSine",
          duration: 500,
          delay: (el, i) => 1500 + i * 250
        })
      let animation2 = anime
        .timeline({
          autoplay: false,
          loop: false,
          endDelay: 500
        })
        .add({
          targets: ".trudeauWrapper",
          opacity: [0, 1],
          easing: "easeInOutSine",
          duration: 500,
          delay: 1500
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
      animation2.seek(animation.duration * progress)
    },
    startLoop() {
      anime({
        targets: ".particle",
        width: ["32%", "35%"],
        direction: "alternate",
        loop: true,
        duration: 1200,
        easing: "easeInOutSine"
      })
    }
  },
  watch: {
    innerPosition() {
      this.trudeau(this.innerPosition)

      this.slide7(this.innerPosition)
    }
  }
}
</script>

<style></style>
